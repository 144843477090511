.new-mark{
  align-items: center;
  background-color: var(--main-color);
  bottom: -50px;
  border: 1px solid var(--color-blackish);
  border-radius: 50%;
  display: flex;
  font-family: var(--font-secondary);
  font-size: 1.333rem;
  font-weight: 700;
  justify-content: center;
  height: 100px;
  position: absolute;
  right: 5%;
  transform: rotate(-10deg);
  width: 100px;
}

.schedule-mark{
  background-color: var(--main-color);
  border: 1px solid var(--color-blackish);
  color: var(--color-black);
  font-family: var(--font-secondary);
  font-size: 1.333rem;
  padding: 3px;
}

.schedule-mark--on-teaser{
  bottom: 10px;
  left: 0;
  position: absolute;
}

.article-teaser{
  background-color: var(--color-white);
  border: 1px solid var(--color-blackish);
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 12px;
  transition: box-shadow .3s;

  &:hover{
    box-shadow: -2px -2px 16px 0px var(--main-color);
  }
}

.article-teaser--fixed{
  flex-shrink: 0;
  width: 300px;

  @media (min-width: 960px) {
    width: 350px;
  }
}

.article-teaser--laboratori{
  --main-color: var(--color-term-laboratori);
}

.article-teaser--eventi{
  --main-color: var(--color-term-eventi);
}

.article-teaser--materiali-didattici{
  --main-color: var(--color-term-materiali-didattici);
}

.article-teaser__figure{
  position: relative;
}

.article-teaser__image{
  border-radius: 12px;
  display: block;
  height: auto;
  width: 100%;
}

.article-teaser__content{
  flex-grow: 1;
  margin-top: 9px;
}

.article-teaser__header{
  margin-bottom: 12px;
}

.article-teaser__eyelet{
  font-family: var(--font-primary);
  font-weight: 800;
  margin-bottom: 12px;
  margin-top: 6px;
}

.article-teaser__title{
  font-size: 1.667rem;
  font-family: var(--font-primary);
  font-weight: 800;
}

.article-teaser__footer{
  margin-top: 15px;
}