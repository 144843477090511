.testimonial{
  border: 1px solid var(--color-blackish);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 12px;

  p{
    font-size: 1rem;
  }
}

.testimonial__name{
  display: inline-block;
  font-weight: 800;
  margin-top: 18px;
}