.partners{
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
}

.logos{
  margin-top: 30px;

  @media (min-width: 960px) {
    max-width: 980px;
  }

  @media (max-width: 959px) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

.logos__wrapper{
  align-items: center;
}

.logo{
  display: block;
  width: auto;

  &[src$="png"]{
    height: auto;
  }
}
