.vision{
  padding-top: 100px;

  @media (min-width: 960px){
    padding-top: 200px;
  }
}

.vision__media{
  @media (min-width: 809px) and (max-width: 959px){
    width: 50%;
  }

  @media (min-width: 960px) {
    align-self: stretch;
    position: relative;
    width: 40%;
  }
}

.vision__animation{
  @media (min-width: 960px){
    left: 0;
    position: absolute;
    top: -25%;
  }
}

.vision__text{
  position: relative;

  @media (min-width: 960px) {
    padding-bottom: 160px;
    width: 48%;
  }
}

.vision__cat{
  display: block;

  @media (max-width: 959px) {
    height: 160px;
    left: 100%;
    position: relative;
    transform: translateX(-100%);
    width: 200px;
  }

  @media (min-width: 960px) {
    position: absolute;
    right: 0;
    top: 45%;
    width: 250px;
  }
}
