@import "swiper/scss";
@import "swiper/scss/pagination";

.services{
  padding-bottom: 160px;
  padding-top: 160px;
}

.services__text{
  @media (min-width: 810px) {
    width: 48%;
  }
}

.services__media{
  @media (min-width: 810px) {
    width: 48%;
  }
}

.services__articles{
  margin-top: 60px;
}

.services__title{
  padding-left: 5vw;
  padding-right: 5vw;
}

.articles{
  margin-top: 30px;
  padding-left: 5vw;
  padding-right: 5vw;
  position: relative;
}

.articles__pagination{
  text-align: center;
  margin-top: 30px;
}

.articles__bullet{
  background-color: var(--color-dark-grey);
  border-radius: 50%;
  display: inline-block;
  height: 9px;
  margin-left: 3px;
  margin-right: 3px;
  transition: background-color .3s;
  width: 9px;
}

.articles__bullet--active{
  background-color: var(--color-blue);
}