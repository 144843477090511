.testimonials{
  margin-bottom: 60px;
  margin-top: 60px;
}

.testimonials__content{
  color: var(--color-black);
}

.testimonials__wrapper{
  align-items: center;
}

.testimonials__pagination{
  margin-top: 30px;
  text-align: center;
}