.tag{
  border-radius: 6px;
  border: 1px solid var(--color-blackish);
  color: var(--color-black);
  display: inline-block;
  font-size: 0.667rem;
  font-weight: 800;
  margin-bottom: 6px;
  padding: 3px 6px;
}

.tag--laboratori{
  background-color: var(--color-term-laboratori, var(--main-color));
}

.tag--eventi{
  background-color: var(--color-term-eventi, var(--main-color));
}

.tag--materiali-didattici{
  background-color: var(--color-term-materiali-didattici, var(--main-color));
}

.tag--with-context{
  background-color: var(--main-color);
}