.team{
  padding-bottom: 60px;
  padding-top: 60px;
}

.team__text{
  margin-bottom: 30px;

  @media (min-width: 810px) and (max-width: 959px) {
    width: 50%;
  }

  @media (min-width: 960px) {
    width: 30%;
  }
}

.member{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  text-align: center;

  p{
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 30px;
  }

  @media (min-width: 810px) and (max-width: 959px) {
    width: 50%;
  }

  @media (min-width: 960px) {
    width: 30%;
  }
}

.member__image{
  align-items: center;
  background-color: var(--color-white);
  border-radius: 50%;
  display: flex;
  height: 245px;
  justify-content: center;
  position: relative;
  width: 245px;
}

.member__animation{
  left: -10%;
  position: absolute;
  top: -25%;
  width: 120%;
}

.member__name{
  font-size: 2rem;
  margin-bottom: 21px;
  margin-top: 12px;
}
