.customers{
  padding-bottom: 120px;
  padding-top: 120px;
}

.customers__list{
  @media (min-width: 960px) {
    display: flex;
    justify-content: center;
  }
}

.customer{
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;

  @media (min-width: 960px) {
    &:nth-child(odd) {
      flex-direction: column-reverse;
    }
  }
}

.customers__text{
  padding-bottom: 42px;
}

.customer__title{
  font-size: 1.9rem;
  margin-bottom: 30px;
}

.customer__animation{
  height: 230px;
  width: 310px;

  @media (min-width: 960px) {
    height: 300px;
    width: 370px;
  }
}